<template>
  <div class="container">
    <div class="h2">自助退款申请</div>

    <div class="formBox">
      <van-field v-model="json.trade_no" placeholder="请输入支付宝或微信支付交易单号" input-align="center" :border="false" ref="trade_no"/>
      <div class="mt20"><van-button round type="info" size="large" block @click="onSubmit">立即退款</van-button></div>
    </div>
    <div class="note" v-html="html"></div>
  </div>

</template>
<script>
  import {PostDzRefund} from './services/services'
  export default {
    name:'videoSuccess',
    components: {},
    data () {
      return {
        json:{
          trade_no:''
        },
        html:''
      }
    },
    created(){

    },
    computed:{
    },
    mounted(){
      this.findHtml()
    },
    methods:{
      findHtml(){
        this.html='<p style="margin-bottom:10px;">注意事项：</p><p style="color:#999;">1.本页面为自助退款页面，无需人工审核。提交退费申请后，相关费用将在10分钟内原路退回到您的支付账户中。</p><p style="color:#999;">2.若输入的交易单号查询不到您的订单，请联系在线客服或拨打400-666-1792咨询人工客服联系退费。</p><p style="color:#999;">3.退款成功后，您购买的短剧服务将立即失效，感谢您的理解。</p><p style="margin-top:10px;"><img src="https://image.youzuanmy.vip/image/zdd/284A1627738A6686A558D8E8835FDA6C.jpg" width="100%"/></p>'
      },
      onSubmit(){
        if(this.json.trade_no == ''){
          this.$toast('请输入支付宝或微信支付交易单号')
          this.$refs.trade_no.focus()
          return false
        }
        PostDzRefund(this.json).then(result => {
          if(result.data.code==0){
            this.$toast.success(result.data.msg)
            this.json.trade_no = ''
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{padding:60px 0px 20px;
    .h2{font-size:24px;font-weight:700;padding:30px 0px;text-align:center;}
    .formBox{padding:0px 30px;
      .van-cell{background:none;}

    }
    .note{padding:0px 15px;margin-top:20px;}
  }
</style>
